import * as React from 'react'
import { graphql } from 'gatsby'

import PageLayout from '../components/layout/PageLayout'
import ContentSection from '../components/markdown/ContentSection'

const AboutPage = ({data}) => {

  const page = data.mdx.childStaticPage;
  return (
    <PageLayout
      pageTitle={ page.title }
      image={ page.featureImage }
      editUrl={ page.editUrl } >

      <ContentSection body={data.mdx.body} />

    </PageLayout>
  )
}

export default AboutPage


export const query = graphql`
query {
  mdx(slug: {eq: "about"}) {
    body
    childStaticPage {
      ...PageMetadata
    }
  }
}`
